import React, { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CardHeader from '@mui/material/CardHeader';
import { AppContext } from './Context';
import { getEligibleFamilyMembers } from '../../actions/matrimonialAction';
import { getLoggedInUserId } from '../../actions/authAction';
import { toast } from 'react-toastify';
import SearchBar from './componentUtils/search';
import LoadingSpinner from '../loader';
import './styleCreateMatrimony.css';
import AddIndependentUser from './componentUtils/addIndependentUser';
import CreateMatrimonyCard from './componentUtils/createMatrimonyCard';

interface stateproperties {
  name: string;
  pic_url: string;
  relation: string;
  uid: string;
}

// const dummyProfiles = {
//   status: true,
//   unmarried_members: [
//     {
//       name: 'उमा',
//       pic_url: '',
//       relation: 'पुत्री',
//       uid: '124',
//     },
//     {
//       name: 'ख़ुशाल',
//       pic_url: 'https://s3.ap-south-1.amazonaws.com/paliwalkutumb/profiles%2F7086ba40-8a8b-4439-91e4-dd9dd100f4ee.jpg',
//       relation: 'पुत्र',
//       uid: '163',
//     },
//   ],
// };

const theme = createTheme({
  palette: {
    background: {
      paper: '#fff',
    },
    text: {
      primary: '#173A5E',
      secondary: '#46505A',
    },
    action: {
      active: '#001E3C',
    },
    success: {
      main: '#009688',
      dark: '#009688',
    },
  },
});

function CreateMatrimony() {
  const { activePage, handleNextPage, handleChangePageVal, handleChangeStepVal, handleChangeMultiValues } =
    useContext(AppContext);
  const [members, setMembers] = useState<stateproperties[]>([]);
  const [loading, setLoading] = useState(false);
  const loggedInUserId = getLoggedInUserId();
  const [user, setUser] = useState({});
  const [otherMembers, setOtherMembers] = useState([]);
  const [addIndependentUserForm, setAddIndependentUserForm] = useState(false);
  const [searchParams, setSearchParams] = useState({});

  useEffect(() => {
    getProfiles();
    handleChangeStepVal(0);
  }, []);
  const getProfiles = async () => {
    setLoading(true);
    const response = await getEligibleFamilyMembers({ node_id: loggedInUserId });
    console.log('Eligible members ', response);
    setLoading(false);

    if (response?.status) {
      setMembers(response?.unmarried_members);
    } else {
      console.log('getting error in creatematrimony--->getProfiles()');
      toast.error(`getting error in creatematrimony--->getProfiles(), ${response.error_message}`);
    }
  };

  const handleCreate = (data) => {
    console.log('data :', data);
    handleChangeMultiValues({
      phoneNumber: data?.contact_number,
      firstName: data?.name,
      gautra: data?.gautra,
      caste: data?.subcaste,
      fatherName: data?.father_name,
      village: data?.village,
      gender: data?.gender,
    });
    handleNextPage();
  };

  const handleAction = () => {
    handleNextPage();
  };
  return loading ? (
    <LoadingSpinner message="" />
  ) : (
    <div style={{ display: 'flex', gap: '14px', flexDirection: 'column' }}>
      <ThemeProvider theme={theme}>
        {members.length > 0 ? (
          <Box
            sx={{
              bgcolor: 'background.paper',
              boxShadow: 1,
              borderRadius: 2,
              p: 2,
            }}
          >
            <CardHeader sx={{ margin: '0px', padding: '0px', fontWeight: 'bold' }} title="Your family members" />
            <hr />
            {members.map((item) => {
              return <CreateMatrimonyCard member={item} key={item.uid} />;
            })}

            <hr />
            <div className="footer">
              <div className="bottom-text">You can add your family member which is not in list</div>
            </div>
          </Box>
        ) : (
          'No Family Members present to create Profile'
        )}
        <Box
          sx={{
            bgcolor: 'background.paper',
            boxShadow: 1,
            borderRadius: 2,
            p: 2,
            mt: 2, // Add margin-top for spacing
          }}
        >
          <div style={{ marginBottom: '10px' }}>Create Profile for Others</div>
          <SearchBar
            setSelectedUser={(userData) => setOtherMembers((prevMembers) => [...prevMembers, userData])}
            handleNoneOfTheseClick={(searchParams) => {
              setAddIndependentUserForm(true);
              setSearchParams(searchParams);
            }}
          />
          {otherMembers?.length > 0 &&
            otherMembers.map((item) => <CreateMatrimonyCard member={item} key={item?.uid} />)}
        </Box>
        {addIndependentUserForm && (
          <AddIndependentUser
            showPhoneNoField={true}
            onClose={() => setAddIndependentUserForm(false)}
            handleCreate={handleCreate}
            searchParams={searchParams}
          />
        )}
      </ThemeProvider>
    </div>
  );
}

export default CreateMatrimony;
